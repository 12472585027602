import classes from "./MobileMenu.module.css";

const MobileMenu = () => {
  return (
    <>
      <div className={classes.mobilemenu}>
        <a href="https://newimage.asia/nz">Home</a>
        <a href="https://newimage.asia/nz/work-with-us">Work With Us</a>
        <a href="https://newimage.asia/nz/products">Products</a>
        <a href="https://newimage.asia/nz/about-new-image">Company</a>
        <a href="https://newimage.asia/nz/resources/optimum-health-portal">
          Resources
        </a>

        <a href="https://newimage.asia/nz/contact">Contact</a>
      </div>
    </>
  );
};

export default MobileMenu;
