import CountryContact from "../UI/CountryContact";
import classes from "./FormSection.module.css";
import ContactForm from "../FormPages/ContactForm.tsx";

const FormSection = ({ contactRef }) => {
  return (
    <section ref={contactRef} className={classes.container}>
      <h2 className={classes.heading}>LOOK FIT AND FEEL FIT</h2>
      <h2 className={classes.subheading}>
        Enjoy the many benefits of FibreMax™. Contact us today to start your
        trial.
      </h2>
      <div className={classes.wrapper}>
        <div className={classes.form}>
          <h3 style={{ paddingLeft: "20px" }} className={classes.formheading}>
            Unlock the best deal!<br></br>
            We'll connect you with a New Image™ Member
          </h3>
          <ContactForm />
        </div>

        {/* Section Seperator Line */}
        <div className={classes.seperator}></div>
        {/* End of Seperator */}

        <div className={classes["contact-detail"]}>
          <h3 className={classes.formheading}>
            Can't wait?<br></br> Contact our customer service team direct
          </h3>
          <CountryContact />
        </div>
      </div>
    </section>
  );
};

export default FormSection;
