import { Component } from "react";
import TextInput from "../FormParts/TextInput";
import axios from "axios";
import { validate } from "email-validator";
import SelectInput from "../FormParts/SelectInput";
import classes from "./ContactForm.module.css";
const API = process.env.REACT_APP_API;
interface Props {
  accentColor: string;
}
interface State {
  name: string;
  email: string;
  phone: string;
  to: string;
  sending: boolean;
  sent: boolean;
  error: boolean;
  [key: string]: any;
  errorMessage: string;
  countries: { value: string; email: string }[];
  country: string;
  page: string;
}

export default class Form extends Component<Props, State> {
  state: State = {
    name: "",
    page: "",
    email: "",
    phone: "",
    to: "",
    sending: false,
    sent: false,
    error: false,
    errorMessage: "",
    country: "",
    countries: [
      { value: "New Zealand", email: "nzsupport@newimage.asia" },
      { value: "Australia", email: "ausupport@newimage.asia" },
      { value: "Ghana", email: "ghsupport@newimage.asia" },
      { value: "Indonesia", email: "idsupport@newimage.asia" },
      { value: "Malaysia", email: "mysupport@newimage.asia" },
      { value: "Philippines", email: "phsupport@newimage.asia" },
      { value: "South Africa", email: "safsupport@newimage.asia" },
      { value: "Singapore", email: "sgsupport@newimage.asia" },
      { value: "Thailand", email: "thsupport@newimage.asia" },
      { value: "Taiwan", email: "twsupport@newimage.asia" },
      { value: "Uganda", email: "ugsupport@newimage.asia" },
      { value: "Vietnam", email: "info@newimageasia.vn" },
      { value: "Zambia", email: "kingsley.lilamono@newimage.asia" },
    ],
  };
  componentDidMount() {
    this.setState({ page: window.location.href });
  }

  //nzsupport@newimage.asia

  submitForm(e: any) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ sending: true, sent: false, error: false });
    axios({
      method: "post",
      url: `${API}/product-focus`,
      data: {
        to: this.state.to,
        email: this.state.email,
        phone: this.state.phone,
        page: this.state.page,
        name: this.state.name,
      },
    })
      .then((response: any) => {
        this.setState({
          sending: false,
          to: "",
          sent: true,
          email: "",
          name: "",
          phone: "",
          country: "",
          error: false,
          errorMessage: "",
        });
      })
      .catch((error) => {
        if (!validate(this.state.email)) {
          this.setState({
            errorMessage: "Please enter a valid email address",
            sending: false,
            error: true,
          });
        } else {
          this.setState({
            errorMessage: error.response.statusText,
            sending: false,
            error: true,
          });
        }
      })
      .finally(() => {
        this.setState({ sending: false });
      });
    console.log(this.state.phone);
  }

  editField(value: string, name: any) {
    this.setState({ [name]: value });
  }

  editCountryField(country: any) {
    const sendToCountry =
      this.state.countries.find((c) => c.value === country) ||
      this.state.countries[0];
    this.setState({ to: sendToCountry.email, country });
  }

  render() {
    const { sending, sent, error, country, countries } = this.state;
    return (
      <div className={classes.container}>
        <form onSubmit={this.submitForm.bind(this)}>
          <TextInput
            color={this.props.accentColor}
            {...this.props}
            edit={this.editField.bind(this)}
            type="text"
            required={true}
            placeholder="Enter your name"
            name="name"
            value={this.state.name}
            label="Name"
          />
          <TextInput
            color={this.props.accentColor}
            {...this.props}
            edit={this.editField.bind(this)}
            type="email"
            required={true}
            placeholder="Enter your email"
            name="email"
            value={this.state.email}
            label="Email"
          />
          <TextInput
            color={this.props.accentColor}
            {...this.props}
            edit={this.editField.bind(this)}
            type="tel"
            required={false}
            placeholder="Enter your phone"
            name="phone"
            value={this.state.phone}
            label="Phone"
          />
          <SelectInput
            edit={this.editCountryField.bind(this)}
            name="to"
            value={country}
            label="Select your location"
            options={countries}
            color={this.props.accentColor}
            required={true}
          />

          <button type="submit" className={classes.submitbutton}>
            {sending ? "Submitting" : "Submit"} {sending && <Spinner />}
          </button>
        </form>
        <div style={{ height: "4rem" }}>
          {sent && (
            <div className={classes.successmessage}>
              Thank you, someone will be in touch shortly!
            </div>
          )}
          {error && (
            <div className={classes.errormessage}>
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const Spinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: "-10px  0 -5px 5px",
        background: "transparent",
        display: "inline-block",
      }}
      width="20px"
      height="20px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke="#ffffff"
        strokeWidth={10}
        r={35}
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.8s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  );
};
