import classes from "./SecondSection.module.css";

const SecondSection = ({ contactRef }) => {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.heading}>GIVE NEW IMAGE™ FIBREMAX™ A TRY</h2>
        <h2 className={classes.subheading}>
          We're got a very special offer for first-time{" "}
          <span className={classes.underline}>FibreMax™</span> users! Get
          started with our brand new intro deal.
        </h2>
        <button>
          <h2
            onClick={() => {
              handleScroll(contactRef.current);
            }}
            className={classes.buttontext}
          >
            START YOUR 30-DAY TRIAL TODAY
          </h2>
        </button>
      </div>
    </div>
  );
};

export default SecondSection;
