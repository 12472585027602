import { Component } from "react";
import classes from "./SelectInput.module.css";

interface Props {
  edit: Function;
  name: string;
  value: string;
  label: string;
  options: object[];
  required: boolean;
  color: string;
}

export default class SelectInput extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.blurInput = this.blurInput.bind(this);
  }

  focusInput(e: any) {
    this.setState({ focused: true });
  }
  blurInput(e: any) {
    console.log(e.target.value);
    if (e.target.value > 0) {
      this.setState({ focused: true });
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props && this.props.value) {
      this.setState({ focused: false });
    }
  }
  render() {
    const { required } = this.props;
    return (
      <div className="py-5">
        <label style={{ color: "transparent" }} htmlFor={this.props.name}>
          {this.props.label}
        </label>
        {required ? (
          <select
            required
            name={this.props.name}
            className={classes.selectcontainer}
            onChange={(e) => {
              this.props.edit(e.target.value);
              this.blurInput(e);
            }}
            value={this.props.value}
            onBlur={(e) => {
              this.props.edit(e.target.value);
              this.blurInput(e);
            }}
            onFocus={(e) => {
              this.setState({ focused: true });
            }}
          >
            <option value="" disabled selected hidden>
              Select your location
            </option>
            {this.props.options.map((option: any, key: number) => (
              <option className="text-sm" key={key}>
                {option.value}
              </option>
            ))}
          </select>
        ) : (
          <select
            name={this.props.name}
            className="border-b-2 py-2 capitalize focus:outline-none focus:border-blue-500 px-1 focus:bg-blue-50 transition duration-500 w-full"
            onChange={(e) => {
              this.props.edit(e.target.value);
              this.blurInput(e);
            }}
            value={this.props.value}
            onBlur={(e) => {
              this.props.edit(e.target.value);
              this.blurInput(e);
            }}
            onFocus={() => {
              this.setState({ focused: true });
            }}
          >
            {this.props.options.map((option: any, key: number) => (
              <option key={key}>{option.value}</option>
            ))}
          </select>
        )}
      </div>
    );
  }
}
