import { useState } from "react";
import { offices } from "../../data/offices.js";
import classes from "./CountryContact.module.css";

const CountryContact = () => {
  const [selectedCountry, setSelectedCountry] = useState("");

  const office = offices.find((obj) => {
    return obj.country === selectedCountry;
  });

  console.log(office);

  const selectCountryHandler = (e) => {
    e.preventDefault();
    setSelectedCountry(e.target.value);
  };

  return (
    <>
      <div className={classes.selectmenu}>
        <label style={{ color: "#0093c6" }} htmlFor="label">
          Select your location:
        </label>
        <select id="label" onChange={selectCountryHandler}>
          <option value="" disabled selected hidden>
            Select your country
          </option>
          {offices.map((office) => (
            <option key={office.id} value={office.country}>
              {office.country}
            </option>
          ))}
        </select>
      </div>

      <div style={{ padding: "10px" }}>
        {office && (
          <div key={office.id}>
            {office.email !== "" ? (
              <div className={classes.contactcontainer}>
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <h4>{office.email}</h4>
              </div>
            ) : null}
            {office.phone !== "" ? (
              <div className={classes.contactcontainer}>
                <i class="fa fa-phone fa-lg" aria-hidden="true"></i>
                <h4>{office.phone}</h4>
              </div>
            ) : null}
            {office.altphone !== "" ? (
              <div className={classes.contactcontainer}>
                <i class="fa fa-fax" aria-hidden="true"></i>
                <h4>{office.altphone}</h4>
              </div>
            ) : null}
            {office.location !== "" ? (
              <div className={classes.contactcontainer}>
                <i class="fa fa-map-marker fa-lg" aria-hidden="true"></i>
                <h4>Location: {office.location}</h4>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default CountryContact;
