import classes from "./HeroSection.module.css";
import hero from "../../assets/hero/Hero.jpg";
import heromobile from "../../assets/hero/HeroMobile.jpg";
import herotext from "../../assets/hero/HeroText.png";
import { useState, useEffect } from "react";

const HeroSection = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 788);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 788);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <>
      {isDesktop ? (
        <div className={classes.section}>
          <div className={classes.herotext}>
            <img src={herotext} alt="lookfit feelfit" />
            {/* <h2>look fit</h2>
            <h1>feel fit.</h1> */}
          </div>
          <img src={hero} alt="hero" />
        </div>
      ) : (
        <div className={classes.section}>
          {/* <div className={classes.herotext}>
            <img src={herotext} alt="lookfit feelfit" />
            <h2>look fit</h2>
          <h1>feel fit.</h1>
          </div> */}
          <img src={heromobile} alt="hero" />
        </div>
      )}
    </>
  );
};

export default HeroSection;
