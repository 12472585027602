import classes from "./MobileModal.module.css";
import ReactDOM from "react-dom";
import { Fragment } from "react";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose}></div>;
};

const ModalOverlay = (props) => {
  return (
    <>
      <div className={classes.modal}>
        <div className={classes.content}>{props.children}</div>
      </div>
      <div className={classes.button}>
        <i
          onClick={props.closeModalButton}
          className="fa fa-times fa-lg"
          aria-hidden="true"
        ></i>
      </div>
    </>
  );
};

const portalElement = document.getElementById("overlays");
const MobileModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay closeModalButton={props.onClose}>
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default MobileModal;
