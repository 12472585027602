import { Fragment } from "react";
import logo from "../../assets/international.png";
import ColourGrid from "../UI/ColourGrid";
import MobileMenu from "../UI/MobileMenu";
import MobileModal from "../UI/MobileModal";
import classes from "./Header.module.css";
import { useState } from "react";

const Header = () => {
  const [modal, setModal] = useState(false);
  const openModalHandler = () => {
    setModal(true);
  };

  const closeModalHandler = () => {
    setModal(false);
  };
  return (
    <Fragment>
      <section className={classes.container}>
        <header className={classes.header}>
          <div className={classes.logo}>
            <img src={logo} alt="NI International Logo" />
          </div>
          <div className={classes.mobilenavbar}>
            <i
              className="fa fa-bars fa-2x"
              aria-hidden="true"
              onClick={openModalHandler}
            ></i>
          </div>
          <div className={classes.navbar}>
            <a href="https://newimage.asia/nz">Home</a>
            <a href="https://newimage.asia/nz/work-with-us">Work With Us</a>
            <a href="https://newimage.asia/nz/products">Products</a>
            <div className={classes.dropdown}>
              <button className={classes.dropbtn}>
                Company&nbsp;
                <i className="fa fa-caret-down"></i>
              </button>
              <div className={classes["dropdown-content"]}>
                <a href="https://newimage.asia/nz/company/about-new-image">
                  About New Image
                </a>
                <a href="https://newimage.asia/nz/company/company-news">
                  Company News
                </a>
                <a href="https://newimage.asia/nz/company/embracing-hope">
                  Embracing Hope
                </a>
                <a href="https://newimage.asia/nz/company/events">Events</a>
              </div>
            </div>
            <div className={classes.dropdown}>
              <button className={classes.dropbtn}>
                Resources&nbsp;
                <i className="fa fa-caret-down"></i>
              </button>
              <div className={classes["dropdown-content"]}>
                <a href="https://newimage.asia/nz/resources/optimum-health-portal">
                  Optimum Health Portal
                </a>
                <a href="https://newimage.asia/nz/resources/healthy-living-articles">
                  Healthy Living Articles
                </a>
                <a href="https://newimage.asia/nz/resources/science-of-colostrum">
                  Science of Colostrum
                </a>
              </div>
            </div>
            <a href="https://newimage.asia/nz/contact">Contact</a>
          </div>
        </header>
        <ColourGrid />

        {modal && (
          <MobileModal onClose={closeModalHandler}>
            <MobileMenu />
          </MobileModal>
        )}
      </section>
    </Fragment>
  );
};

export default Header;
