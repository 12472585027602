import classes from "./ColourGrid.module.css";

const ColourGrid = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.item1}></div>
      <div className={classes.item2}></div>
      <div className={classes.item3}></div>
      <div className={classes.item4}></div>
      <div className={classes.item5}></div>
    </div>
  );
};

export default ColourGrid;
