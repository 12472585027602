import React from "react";
import classes from "./FirstVideo.module.css";

const YoutubeEmbed = ({ embedId }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes["embed-border"]}>
        <div className={classes["embed-responsive"]}>
          <iframe
            title="FibreMax Recipe"
            width="560"
            height="315"
            frameBorder="0"
            src={`https://player.vimeo.com/video/${embedId}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default YoutubeEmbed;
