import classes from "./TextInput.module.css";
interface Props {
  edit: Function;
  name: string;
  value: string;
  label: string;
  required: boolean;
  type: string;
  color: string;
  invalid?: boolean;
  placeholder: string;
}

const TextInput = (props: Props) => {
  return (
    <div data-error={props.invalid}>
      <label className={classes.inputlabel} htmlFor={props.name}>
        {props.label}
      </label>
      {props.required ? (
        <input
          className={classes.inputcontainer}
          placeholder={props.placeholder}
          name={props.name}
          type={props.type}
          required
          onChange={(e) => {
            props.edit(e.target.value, props.name);
          }}
          value={props.value}
        />
      ) : (
        <input
          className={classes.inputcontainer}
          placeholder={props.placeholder}
          name={props.name}
          type={props.type}
          onChange={(e) => {
            props.edit(e.target.value, props.name);
          }}
          value={props.value}
        />
      )}
    </div>
  );
};

export default TextInput;
