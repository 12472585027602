import classes from "./FirstVideo.module.css";
import YoutubeEmbed from "../Video/FirstVideo";
const FirstVideo = () => {
  return (
    <div className={classes.section}>
      <div className={classes.wrapper}>
        <div className={classes.fibremax}>
          [[Recipe Name Here]<br></br>
          <br></br> [[Recipe summary here, taken from Keto Website? Nutribullet
          blended, keto-friendly Fibre-Max + Lifeline shake recipe that can be
          added/linked to keto website]]
        </div>
        <div className={classes.flexvideocontainer}>
          <YoutubeEmbed embedId="734566838?h=d50c3d2bc0" />
        </div>
      </div>
    </div>
  );
};

export default FirstVideo;
