export const offices = [
  {
    id: 1,
    email: "nzsupport@newimage.asia",
    country: "New Zealand",
    phone: "+64 9 622 2388",
    altphone: "0800 242 600",
    location: "19 Mahunga Drive, Mangere Bridge, Auckland 2022, New Zealand",
  },
  {
    id: 2,
    email: "ausupport@newimage.asia",
    country: "Australia",
    phone: "1300 655 662",
    altphone: "",
    location: "19 Mahunga Drive, Mangere Bridge, Auckland 2022, New Zealand",
  },
  {
    id: 3,
    email: "safsupport@newimage.asia",
    country: "Africa",
    phone: "+27 31 015 5893",
    altphone: "",
    location:
      "Talksure Building, 62 Umhlanga Ridge Boulevard, Umhlanga Ridge, Umhlanga 4319, KwaZulu-Natal, South Africa",
  },
  {
    id: 4,
    email: "ghsupport@newimage.asia",
    country: "Ghana",
    phone: "+23 324 496 4277",
    altphone: "",
    location: "1B First Freetown, Link Okponglo, East Legon , Accra, Ghana",
  },
  {
    id: 5,
    email: "idsupport@newimage.asia",
    country: "Indonesia",
    phone: "+62 21 653 105 67",
    altphone: "+62 21 653 105 68",
    location:
      "Rukan Puri Mutiara, Jl. Griya Utama blok A No. 100, Jakarta Utara, Indonesia.",
  },
  {
    id: 6,
    email: "mysupport@newimage.asia",
    country: "Malaysia",
    phone: "+60 3 7940 3688",
    altphone: "+60 3 2178 4025",
    location:
      "G-2, Axis Technology Centre Lot.13, Jalan 225, Seksyen 51A, 46100 Petaling Jaya, Selangor Darul Ehsan, Malaysia.",
  },
  {
    id: 7,
    email: "ngsupport@newimage.asia",
    country: "Nigeria",
    phone: "234 810 797 1503",
    altphone: "",
    location:
      "7th Floor, Mulliner Towers, 39 Alfred Rewane Road, Ikoyi, Lagos Nigeria",
  },
  {
    id: 8,
    email: "phsupport@newimage.asia",
    country: "Philippines",
    phone: "+63 2 8687 3161",
    altphone: "+63 82 222 0838",
    location:
      "Unit 1806, Centerpoint Building, Garnet Road, Corner Julia Vargas Avenue, Ortigas Centre, Pasig City, 1605, Philippines",
  },
  {
    id: 9,
    email: "sgsupport@newimage.asia",
    country: "Singapore",
    phone: "+65 6 838 6766",
    altphone: "+65 6 737 0109",
    location: "140 Paya Lebar Road, #01-08, AZ @ Paya Lebar Singapore, 409015",
  },
  {
    id: 10,
    email: "safsupport@newimage.asia",
    country: "South Africa",
    phone: "+27 31 015 5893",
    altphone: "",
    location:
      "Talksure Building, 62 Umhlanga Ridge Boulevard, Umhlanga Ridge, Umhlanga 4319, KwaZulu-Natal, South Africa",
  },
  {
    id: 11,
    email: "thsupport@newimage.asia",
    country: "Thailand",
    phone: "+66 (0) 2043 7895",
    altphone: "",
    location:
      "1338/6, 1st floor Show room, Supalai Prima Riva, Rama 3 Chongnoonsri, Yannawa, BKK 10120",
  },
  {
    id: 12,
    email: "ugsupport@newimage.asia",
    country: "Uganda",
    phone: "+256 200 900 887",
    altphone: "",
    location:
      "Kampala Boulevard Building, 1st Floor, Plot 22/26, Office F22, Kampala Road, Kampala, Uganda.",
  },
  {
    id: 13,
    email: "kingsley.lilamono@newimage.asia",
    country: "Zambia",
    phone: "+260 979 311 133",
    altphone: "",
    location: "",
  },
];
