import CartProvider from "./store/CartProvider";
import HeroSection from "./components/Layout/HeroSection";
import FirstVideo from "./components/Layout/FirstVideo";
import SecondVideo from "./components/Layout/SecondVideo";
import Header from "./components/Layout/Header";
import SecondSection from "./components/Layout/SecondSection";
import MainSection from "./components/Layout/MainSection";
import FormSection from "./components/Layout/FormSection";
import { useRef } from "react";

function App() {
  const contactRef = useRef(null); //represents contact section
  return (
    <CartProvider>
      <Header />
      <HeroSection />
      <SecondSection contactRef={contactRef} />
      <MainSection />
      <FirstVideo />
      <SecondVideo />
      <FormSection contactRef={contactRef} />
    </CartProvider>
  );
}

export default App;
