import classes from "./SecondVideo.module.css";
import YoutubeEmbed from "../Video/FirstVideo";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button className={classes.next} onClick={() => swiper.slideNext()}>
      {children}
    </button>
  );
};

const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button className={classes.prev} onClick={() => swiper.slidePrev()}>
      {children}
    </button>
  );
};

const FirstVideo = () => {
  return (
    <div className={classes.section}>
      <Swiper
        modules={[Navigation, Pagination]}
        className={`${classes["swiper-container"]}`}
        spaceBetween={90}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <div className={classes.wrapper}>
            <SwiperButtonPrev>
              <i
                className="fa fa-chevron-circle-left fa-2x"
                aria-hidden="true"
              ></i>
            </SwiperButtonPrev>
            <SwiperButtonNext>
              <i
                className="fa fa-chevron-circle-right fa-2x"
                aria-hidden="true"
              ></i>
            </SwiperButtonNext>
            <div className={classes.flexvideocontainer}>
              <YoutubeEmbed embedId="734566838?h=d50c3d2bc0" />
            </div>
            <div className={classes.fibremax}>
              “Ditiae nemporessed undis eturem sus, quame denecab oreheniment
              undam nonsequi voloriam eum doluptius re evelenes accaboris
              volorep udant” ~ <br></br>
              <br></br>[Name Here], [Country/City Here]
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={classes.wrapper}>
            <SwiperButtonPrev>
              <i
                className="fa fa-chevron-circle-left fa-2x"
                aria-hidden="true"
              ></i>
            </SwiperButtonPrev>
            <SwiperButtonNext>
              <i
                className="fa fa-chevron-circle-right fa-2x"
                aria-hidden="true"
              ></i>
            </SwiperButtonNext>
            <div className={classes.flexvideocontainer}>
              <YoutubeEmbed embedId="734566838?h=d50c3d2bc0" />
            </div>
            <div className={classes.fibremax}>
              “Ditiae nemporessed undis eturem sus, quame denecab oreheniment
              undam nonsequi voloriam eum doluptius re evelenes accaboris
              volorep udant” ~ <br></br>
              <br></br>[Name Here], [Country/City Here]
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={classes.wrapper}>
            <SwiperButtonPrev>
              <i
                className="fa fa-chevron-circle-left fa-2x"
                aria-hidden="true"
              ></i>
            </SwiperButtonPrev>
            <SwiperButtonNext>
              <i
                className="fa fa-chevron-circle-right fa-2x"
                aria-hidden="true"
              ></i>
            </SwiperButtonNext>
            <div className={classes.flexvideocontainer}>
              <YoutubeEmbed embedId="734566838?h=d50c3d2bc0" />
            </div>
            <div className={classes.fibremax}>
              “Ditiae nemporessed undis eturem sus, quame denecab oreheniment
              undam nonsequi voloriam eum doluptius re evelenes accaboris
              volorep udant” ~ <br></br>
              <br></br>[Name Here], [Country/City Here]
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default FirstVideo;
