import classes from "./MainSection.module.css";
import fibremax from "../../assets/secondsection/fibremax.png";
import lookfit from "../../assets/secondsection/Look Fit.png";
import feelfit from "../../assets/secondsection/Feel Fit.png";

const MainSection = () => {
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.firstsection}>
          <div style={{ paddingLeft: "20px" }}>
            <img src={lookfit} alt="look fit" width={"260px"} height={"auto"} />

            <h3 className={classes.heading}>LOSE WEIGHT THE NATURAL WAY</h3>
            <ul>
              <li>
                <h3 className={classes.subheading}>
                  ► Formulated to help you reach your<br></br> weight loss
                  goals.
                </h3>
              </li>
              <br></br>
              <li>
                <h3 className={classes.subheading}>
                  ► Just one 15g serve provides 40% of<br></br> your recommended
                  daily fibre.
                </h3>
              </li>

              <br></br>

              <li>
                <h3 className={classes.subheading}>
                  ► Harness the power of soluble fibre<br></br> to regulate a
                  healthy appetite and feel<br></br> fuller for longer.
                </h3>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.secondsection}>
          <img src={fibremax} alt="fibremax" />
        </div>
        <div className={classes.thirdsection}>
          <div style={{ paddingRight: "20px" }}>
            <img src={feelfit} alt="feel fit" width={"260px"} height={"auto"} />

            <h3 className={classes.heading}>A WINNING COMBINATION</h3>
            <ul>
              <li>
                {" "}
                <h3 className={classes.subheading}>
                  ► Combine <span className={classes.underline}>FibreMax™</span>{" "}
                  with our<br></br> colostrum hero{" "}
                  <span className={classes.underline}>
                    Alpha Lipid™ Lifeline™<br></br>
                  </span>
                  for a nutritious start to your day.
                </h3>
              </li>
              <br></br>
              <li>
                <h3 className={classes.subheading}>
                  ► Enjoy the health benefits of fibre with<br></br> the immune
                  building power<br></br>of Colostrum.
                </h3>
              </li>
              <br></br>

              <li>
                <h3 className={classes.subheading}>
                  ► A daily boost to support a healthy gut<br></br>and digestive
                  comfort.
                </h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
